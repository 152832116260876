<template>
  <div>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">

      <section class="content-header p-1 px-2">
        <div class=" div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <!-- <span></span> -->
              <h4 style="color: #0007" class="mt-3">
                <i class="fa fa-user-plus mx-1"></i>
                Adicionar Usuário
              </h4>
              <router-link to="/admin/users" class="btn btn-outline-secondary btn-sm"
                           title="voltar">
                <i class="fa fa-arrow-left mx-1"></i>Voltar
              </router-link>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="row " id="NRC">
          <div class="d-flex col-12 mt-4">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="name">Nome</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control"
                  placeholder="Nome..."
                  v-model="admin.name"
              />
            </div>
          </div>

          <div class="d-flex col-12">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="perfil">Perfil</label>
            </div>
            <div class="form-group col-lg-6">
              <select
                  type="text"
                  id="perfil"
                  name="perfil"
                  class="form-control"
                  placeholder="Perfil"
                  v-model="admin.perfil"
              >
                <option v-for="(item, index) in roles" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
          </div>

          <div v-if="
            ['admin'].includes($store.state.admin.perfil) &&
            admin.perfil &&
            $store.state.admin.is_admin
          " class="d-flex col-10">
            <div class="label d-flex justify-content-end col-lg-4">
              <label class="word-custom" for="is_admin"></label>
            </div>
            <div class="form-group col-lg-4">
              <input
                id="is_admin"
                type="checkbox"
                v-model="admin.is_admin"
              />
              <span class="word-custom-radio"> Habilitar acesso master.</span>
            </div>
          </div>
          <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Permissões</label>
          </div>
          <div class="form-group col-lg-6">
            <div class="form-check form-switch">
             <div class="custom-control custom-switch">
              <input type="checkbox"  v-model="admin.associated_seller" class="custom-control-input" id="associatedSeller">
              <label class="custom-control-label" for="associatedSeller">Vendedor habilitado a realizar vendas associadas</label>            
          </div>
          </div>
          </div>
        </div>
          <div class="d-flex col-12">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="email">Email</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  placeholder="Email..."
                  v-model="admin.email"
              />
            </div>
          </div>

          <div class="d-flex col-12">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="password">Senha</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="password"
                  name="password"
                  id="password"
                  class="form-control"
                  placeholder="Senha..."
                  v-model="admin.password"
              />
            </div>
          </div>

          <div class="d-flex col-12">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="confirmPassword">Confirmar Senha</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  class="form-control"
                  placeholder="Confirmar Senha..."
                  v-model="admin.confirmPassword"
              />
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-lg-12  d-flex justify-content-center ">
            <button class="btn-big btn btn-default" @click="clear()">Limpar</button>
            <button class="btn-big btn btn-primary ml-3"
                    @click="signUp()">Cadastrar
            </button>
          </div>
        </div>
      </section>


    </div>
    <!-- /.content-wrapper -->

  </div>
</template>

<script>

export default {
  data() {
    return {
      admin: {
        associated_seller: false,
        associated_seller_email: ""
      },
      integratedEmail: "",
      roles: ['Administrator', 'Vendedor', 'Autor', 'Suporte', 'Financeiro'],
    }
  },
  //remover esse watch depois da virada
  watch: {
    'admin.associated_seller'(value){
      this.admin.associated_seller = value
      if (value === false) {
        this.admin.associated_seller_email = "";
      }
    },
    'admin.email'(value){
        this.autoCompleteEmailRdStation(value)
    },
    integratedEmail(email){
      this.admin.associated_seller_email = email
      
    }
  },
  methods: {
    signUp() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

      switch (this.admin.perfil) {
        case 'Administrator':
          this.admin.perfil = 'admin';
          break;

        case 'Vendedor':
          this.admin.perfil = 'seller';
          break;

        case 'Autor':
          this.admin.perfil = 'author';
          break;

        case 'Suporte':
          this.admin.perfil = 'support';
          break;

        case 'Financeiro':
          this.admin.perfil = 'finance';
          break;
      }

      this.admin.creator = this.$store.state.admin.name

      this.$tallos_admin_api.post(`auth/signUp`, {...this.admin})
          .then(resp => {
            console.log(this.admin)   

            this.$toasted.global.defaultSuccess({msg: `${this.admin.name} Adicionado.`})
            this.$router.push('/admin/users')
          })
          .catch(e => {
          e.response.status == 404 ? this.$toasted.global.defaultError({ msg: 'Usuário não encontrado na RD. Acione o suporte' }) : this.$toasted.global.defaultError({ msg: e.response.data });

          })
    },
    //remover esse metodo depois da virada
    autoCompleteEmailRdStation(value){
      const email = value;
      const strangeEmail = email.replace(/([^@]+)@/, '$1+rdstation@');
      if(this.admin.associated_seller === true){
        this.integratedEmail = strangeEmail
      }
    },
    clear() {
      this.admin = {
        associated_seller: false
      }
    }
  },

  mounted() {
    this.admin = {
      associated_seller: false
    }
    document.title = 'Tallos Usuários - Cadastrar Usuários'
  }
}
</script>

<style scoped>

.content-wrapper {
  background-color: #fff;
}

.btn-big {
  width: 180px;
  padding: 8px;
}

</style>